.noselect {
  user-select: none;
  /**
   * When an element is touched and held on iOS, Safari displays a menu with
   * various actions about the link such as share, copy, open in new tab…
   * `-webkit-touch-callout: none;` disables this behavior.
   *
   * https://medium.com/appscope/designing-native-like-progressive-web-apps-for-ios-1b3cdda1d0e8
   */
  -webkit-touch-callout: none;
}

.scrolling * { pointer-events: none !important; }
