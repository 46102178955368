.footer {
  position: relative;
  z-index: 1;

  padding: 3rem v(x-space) 2.5rem;

  background: v(accent);

  @media (--tablet-7) {
    padding: 6rem;
  }

  &,
  a {
    color: v(bg);
  }
}

.footer__inner {
  @media (--tablet-7) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.footer__links {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;

  @media (--to-tablet-7) {
    margin-top: 4rem;
  }
}

.footer__link {
  display: inline-block;
  padding: 0 1.5rem;
}
