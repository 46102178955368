.accent {
  color: v(accent);
}

.color-reverse {
  background: v(blue);
  color: v(bg);
}

.grey {
  color: v(grey)
}
