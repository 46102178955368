.altavia-act {
  padding: 9.3rem v(x-space);

  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    transform: rotate(.5turn);

    pointer-events: none;

    background: repeat-x url('../img/illu-pray.svg') calc(50% + 10rem) 25rem / 21rem 35rem;
    opacity: .2;
  }

  &::after {
    background-position: calc(50% - 1rem) -9rem;
  }
}

.altavia-act__inner {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (--to-tablet-9) {
    justify-content: center;
    align-items: center;
  }

  &::after {
    content: '';
    position: absolute;

    transform: rotate(.5turn);

    top: -21.3rem;
    left: calc(50% + 18.5rem);

    size: 42.5rem 45rem;

    background: no-repeat url('../img/illu-pray.svg') bottom / cover;

    @media (--to-tablet-7) {
      display: none;
    }
  }
}

.altavia-act__cta {
  margin-top: 3rem;
}
