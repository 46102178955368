.none {
  display: none;
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  size: 1px;
  margin: -1px;
  padding: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  overflow: hidden;

  border: 0;

  white-space: nowrap;
}
