$header-w: (
  'header-compact': 35em, // 560px
);

@include --w($header-w);

.header {
  position: relative;
  padding: 3rem v(x-space) 5rem;

  @media (--tablet-9) {
    padding: 6rem v(x-space) 15rem;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: 0;

    pointer-events: none;

    background: repeat-x url('../img/illu-cross-legged.svg') 50% 13rem / 30.2rem 21.4rem;
    opacity: .2;

    @media (--tablet-9) {
      background-position: 50% 50%;
      background-size: 43.8rem 30.9rem;
    }
  }

  &::after {
    background-repeat: repeat;
    background-position: calc(50% - 15.1rem) -21.8rem;
    background-size: 30.2rem 42.8rem;

    @media (--tablet-9) {
      background-position: calc(50% - 21.8rem) calc(50% - 33.6rem);
      background-size: 43.8rem 66.8rem;
    }
  }

  > * {
    position: relative;
    z-index: 1;
  }
}

.header__logo {
  margin-bottom: 4.5rem;

  @media (--tablet-9) {
    margin-bottom: 8.3rem;
  }
}

.header__logo__svg {
  @media (--tablet-9) {
    size: 15.5rem 5rem;
    transform: translateX(-6rem);
  }
}

.header__title {
  @media (--to-header-compact) {
    max-width: 10ch;
  }
}

.header__intro {
  max-width: 11ch;

  @media (--header-compact) {
    max-width: 31ch;
  }
}

.header__links {
  margin-top: 4rem;

  @media (--tablet-7) {
    display: flex;
  }
}

.header__linkItem {

  @include all-but(last) {
    @media (--to-tablet-7) {
      margin-bottom: 1.5rem;
    }

    @media (--tablet-7) {
      margin-right: 2rem;
    }
  }
}

.header__deco {
  position: absolute;

  @media (--to-tablet-9) {
    display: none;
  }
}

.header__deco--crossed-legged {
  left: calc(50% + 0rem);
  bottom: -10.6rem;

  size: 37.3rem 30.6rem;

  background: no-repeat url('../img/illu-cross-legged.svg') center / cover;
}

.header__deco--cascade {
  left: calc(50% + 38rem);
  bottom: -.4rem;

  size: 29rem 51.5rem;

  background: no-repeat url('../img/illu-cascade.svg') center / cover;
}
