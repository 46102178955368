/**
 * ALL BUT
 *
 * Add `:first-child` and `:last-child` support to the
 * mixin `all-but()`, which is part of family.scss.
 *
 * Use:
 *  @include all-but(1) {}
 *  @include all-but(first) {}
 *  @include all-but(last) {}
 *  @include all-but(4) {}
 *  @include all-but(-2) {}
 *
 * Improvments:
 *  Go on with the pull request.
 */

@mixin all-but($num) {
  @if ($num == 1 or $num == first) {
    &:not(:first-child) {
      @content;
    }
  }

  @else if ($num == last) {
    &:not(:last-child) {
      @content;
    }
  }

  @else {
    &:not(:nth-child(#{$num})) {
      @content;
    }
  }
}
