.revealer {
  --pic-w: 80rem;
  --pic-h: 80rem;

  position: relative;
}

.revealer--ready {
  cursor: grabbing;

  &::after {
    content: '';
    position: absolute;
    inset: 0 unset 0 0;
    transform: translateX(v(clip, v(pic-w, 0)));

    width: .2rem;

    pointer-events: none;

    background: #fff;
    opacity: .6;
  }
}

.revealer__thumb {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -1.5rem;
  transform: translate(v(clip, v(pic-w, 0)), -50%);

  size: 3rem;

  pointer-events: none;

  background: #fff;
  border-radius: 50%;

  .revealer--not-ready & {
    display: none;
  }
}

.revealer__pic {
  padding: v(revealer-padding, 0);

  pointer-events: none;
}

.revelear__pic--after {
  position: absolute;
  inset: 0;

  clip: rect(0 var(--pic-w) var(--pic-h) var(--clip));
}
