.showreel__video {
  margin: 5rem auto 0;

  width: auto;
  max-height: 90vh;

  background: #645af8 url('/img/bg-showreel.png') center / 50% auto no-repeat;

  @media (--tablet-7) {
    margin-top: 8rem;
  }
}
