.f500 {
  font-weight: 500;
}

.truncated-text {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.nowrap {
  white-space: nowrap;
}
