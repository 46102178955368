.cta {
  height: 5rem;
  padding: 0 v(cta-x-padding, 3rem);

  font-weight: 500;
  font-size: 1.6rem;

  border: .2rem solid rgba(#fff, .5);
  border-radius: 2.5rem;
  color: v(bg);

  transition: border-color $in-out-quad .15s, transform $in-out-back .4s;

  &:hover,
  &:focus {
    transform: scale(1.05);

    .svg-download__arrow {
      animation-name: svg-arrow-up-down;
    }
  }

  @media (--to-phone-large) {
    --cta-x-padding: 2.5rem;
  }
}

.cta--main {
  background: v(btn-bg);
  border-color: v(btn-bg);

  &:hover,
  &:focus {
    border-color: v(bg);
  }
}

.cta__icon {
  margin-left: 1.5rem;
}
