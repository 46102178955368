.h {
  font-weight: 700;
  line-height: 1;
}

.h1 {
  font-size: 3.6rem;

  @media (--tablet-7) {
    font-size: 5rem;
  }
}

.h2 {
  font-size: 3rem;
  line-height: 1.2;

  @media (--tablet-7) {
    font-size: 3.6rem;
  }
}

.h3 {
  font-size: 2.5rem;
  font-weight: 500;
}
