/* Ubuntu v15, September 2020

  Light       300
  Regular     400
  Medium      500
  Bold        700

*/


@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/ubuntu/ubuntu-300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/ubuntu/ubuntu-300-italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/ubuntu/ubuntu-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/ubuntu/ubuntu-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/ubuntu/ubuntu-500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/ubuntu/ubuntu-500-italic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/ubuntu/ubuntu-700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../../fonts/ubuntu/ubuntu-700-italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
