.section--usps {
  padding: 5rem v(x-space);

  background: $wild-sand;

  @media (--tablet-7) {
    padding: 12rem v(x-space);
  }
}

.uspItem {
  margin-top: 5rem;

  display: flex;

  @media (--to-grid) {
    flex-direction: column;
  }

  @media (--grid) {
    margin-top: 8rem;
  }

  @include even {
    @media (--grid) {
      .usp__pics {
        order: 1;
        margin-right: 0;
        margin-left: 6rem;
      }
    }
  }
}

.usp__text {
  @media (--grid) {
    padding-top: 10rem;
  }
}

.usp__icon {
  margin-right: 1.8rem;
}

.usp__pics {
  order: -1;

  flex-shrink: 0;
  width: 100%;

  @media (--to-grid) {
    margin-bottom: 3rem;
  }

  @media (--grid) {
    margin-right: 6rem;
    max-width: 76.5rem;
  }
}

.usp__picCtn {
  --revealer-padding: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #acd0eb url('/img/bg-before-after.jpg') center / cover no-repeat;

  @media (--tablet-7) {
    --revealer-padding: 4rem;
  }
}

.usp__pic {
  max-size: 100% 25rem;
  height: auto;

  @media (--tablet-7) {
    max-height: 50rem;
  }
}

// Art direction

.usp__pic--brush-before {
  transform: translateX(25%);
}
